import React from "react"
import { Button, Col, Collapse, Icon, Popover, Row, Typography } from "antd"
import { graphql } from "gatsby"
import Shell from "../components/Shell/Shell"
import Meta from "../components/Meta/Meta"
import Hero from "../components/SharpHero/SharpHero"
import BookingBar from "../components/BookingBar/BookingBar"
import GoogleMap from "../components/GoogleMap/GoogleMap"
import SeoHeader from "../components/SeoHeader/SeoHeader"

const rowProps = {
  align: "middle",
  gutter: 32,
  justify: "center",
  type: "flex",
}

const fullColProps = {
  xs: 22,
  md: 20,
  lg: 18,
  xl: 16,
}

const directions = [
  {
    from: "From Greenville-Spartanburg International Airport",
    steps: `
      Take I-85 S from GSP Dr.
      Take Exit 35 (SC-86 toward Easley/Piedmont) (20.3 mi)
      Turn right on SC-86
      Turn left on Major Rd.
      Continue onto Moore Rd.
      Continue onto SC-8 W/SC-88
      Turn right onto 18 Mile Rd/State Rd. S 39-28
      Turn left to merge onto US-123 S toward Clemson
      Make a U-turn at SC-93 S.
      Lakeside Lodge Clemson will be on the left.
    `,
  },
  {
    from: "From the south (Atlanta)",
    steps: `
      Take I-75 N/I-85 N
      Take exit 2 (SC-59 toward Fair Play/Seneca) (68.4 mi)
      Turn left on SC-59 N
      Turn right onto SC-24 E/SC-59 N
      Turn left onto SC-59 N
      Turn right onto State Rd. S-37-488
      Continue onto State Rd. S-37-56
      Turn right onto US-123 N/US-76 E
      Lakeside Lodge Clemson will be on the left.
    `,
  },
  {
    from: "From the west (Tennessee)",
    steps: `
      Take I-24 E or I-75 S and I-85N
      Take exit 2 (SC-59 toward Fair Play/Seneca) (68.4 mi)
      Turn left on SC-59 N
      Turn right onto SC-24 E/SC-59 N
      Turn left onto SC-59 N
      Turn right onto State Rd. S-37-488
      Continue onto State Rd. S-37-56
      Turn right onto US-123 N/US-76 E
      Lakeside Lodge Clemson will be on the left.
    `,
  },
  {
    from: "From the east (Virginia)",
    steps: `
      Take VA-24 W to US-29 S (61.3 mi) toward Greensboro
      Take I-85 S to US-123 N/US-76 E (261 mi)
      Take exit 40 for SC-153 toward Easley
      Turn right onto SC-153
      Merge onto US-123 S
      Use the left two lanes to turn slightly left to stay on US-123 S
      Make a U-turn at SC-93 S.
      Lakeside Lodge Clemson will be on the left.
    `,
  },
]

const popoverStyle = { borderBottom: `2px dotted #4296b4`, cursor: `pointer` }

const AddressPopover = ({ street, locality }) => (
  <Popover
    content={
      <Typography.Text copyable>{`${street}, ${locality}`}</Typography.Text>
    }
    placement="bottom"
  >
    <span style={popoverStyle}>address</span>
  </Popover>
)

const PhonePopover = ({ num, text }) => (
  <Popover
    content={
      <Button size="large" type="primary">
        <a href={`tel:${num}`} style={{ fontWeight: 600 }}>
          Click to Call
        </a>
      </Button>
    }
    placement="bottom"
  >
    <span style={popoverStyle}>{text}</span>
  </Popover>
)

export default ({ data }) => {
  const { hero } = data
  const meta = {
    seo: {
      title: "Location | Lakeside Lodge Clemson",
      img: hero.localFile.childImageSharp.fluid.src,
      imgAlt: hero.description,
    },
    uri: "/location/",
  }

  return (
    <Shell>
      <Meta {...meta} />
      <Hero
        caption="Location"
        contentfulAsset={data.hero}
        height={`40vh`}
        imageStyle={{
          filter: `brightness(0.65)`,
          objectPlacement: `top`,
        }}
      />
      <BookingBar showCTAButtons={false} />
      <Row
        {...rowProps}
        style={{
          marginBottom: 48,
          marginLeft: `auto`,
          marginRight: `auto`,
          marginTop: 24,
          maxWidth: 1360,
        }}
      >
        <Col {...fullColProps}>
          <SeoHeader
            seo={{ level: 1, content: "Location" }}
            human={{ level: 4, content: "Come On Down, Y'all" }}
          />
          <Typography.Paragraph>
            Lakeside Lodge Clemson is not far from Clemson fans and alumni
            around the country. Find directions below, enter our{" "}
            <span>
              <AddressPopover
                street={data.map.streetAddress}
                locality={data.map.cityStateZip}
              />
            </span>{" "}
            into your GPS, or{" "}
            <span>
              <PhonePopover num={data.map.phone} text="give us a call" />
            </span>{" "}
            if you get lost.
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row
        {...rowProps}
        style={{
          marginBottom: 48,
          marginLeft: `auto`,
          marginRight: `auto`,
          marginTop: 48,
          maxWidth: 1360,
        }}
      >
        <Col {...fullColProps}>
          <Typography.Title level={4}>Directions:</Typography.Title>
          <Collapse
            accordion
            bordered={false}
            // defaultActiveKey={["0"]}
            expandIcon={({ isActive }) => (
              <Icon rotate={isActive ? 90 : 0} type="caret-right" />
            )}
          >
            {directions.map((dir, index) => (
              <Collapse.Panel
                header={<Typography.Text strong>{dir.from}</Typography.Text>}
                key={index}
              >
                <div className="print">{dir.steps}</div>
              </Collapse.Panel>
            ))}
          </Collapse>
        </Col>
      </Row>
      <Row {...rowProps} style={{ marginTop: 48 }}>
        <Col span={24} style={{ marginBottom: -32 }}>
          <GoogleMap
            center={{
              // lat: data.map.location.lat,
              lat: 34.684998,
              // lng: data.map.location.lon,
              lng: -82.84952,
            }}
            disableDefaultUI
            markers={[
              {
                address: {
                  street: data.map.streetAddress,
                  locality: data.map.cityStateZip,
                },
                label: "Resort & Sales",
                lat: data.map.location.lat,
                lng: data.map.location.lon,
              },
              {
                address: {
                  street: "1 Avenue of Champions",
                  locality: "Clemson, SC 29634",
                },
                color: "#FF9600",
                label: "Memorial Stadium",
                lat: 34.6856763,
                lng: -82.8480031,
              },
            ]}
            noMapType
            noScroll
          />
        </Col>
      </Row>
    </Shell>
  )
}

export const query = graphql`
  {
    hero: contentfulAsset(contentful_id: { eq: "6RfGc2mYqqwsurMoHJUKGB" }) {
      localFile {
        childImageSharp {
          fluid(
            jpegProgressive: true
            jpegQuality: 80
            maxWidth: 1500
            webpQuality: 80
          ) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
    }
    map: contentfulHotel(name: { eq: "Lakeside Lodge Clemson" }) {
      cityStateZip
      location {
        lat
        lon
      }
      phone
      streetAddress
    }
  }
`
