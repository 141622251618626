import React from "react"
import { Popover } from "antd"
import GoogleMapReact from "google-map-react"
import settings from "./settings.json"
import style from "./GoogleMap.module.scss"

function dirLink({ street, locality }) {
  const addr = `${street} ${locality}`.replace(/ /g, "+")
  return `https://google.com/maps/dir/?api=1&destination=${addr}`
}

const MapMarker = ({ address, color, label, lat, lng }) => (
  <Popover
    content={
      <>
        <span>
          {address.street}
          <br />
          {address.locality}
        </span>
        <br />
        <a href={dirLink(address)} rel="noopener noreferrer" target="_blank">
          Directions (Google Maps)
        </a>
      </>
    }
    title={label}
  >
    <div
      className={style.markerWrapper}
      lat={lat}
      lng={lng}
      style={{ background: color ? color : `` }}
    ></div>
  </Popover>
)

export default class GoogleMap extends React.Component {
  constructor(props) {
    super(props)
    this.options = {
      panControl: this.props.noPan ? false : true,
      mapTypeControl: this.props.noMapType ? false : true,
      streetViewControl: this.props.streetView ? true : false,
      scrollwheel: this.props.noScroll ? false : true,
      disableDefaultUI: this.props.disableDefaultUI ? true : false,
      styles: settings,
    }
  }

  render() {
    return (
      <div className={style.mapWrapper}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_API_KEY }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom || 14}
          options={this.options}
        >
          {this.props.markers.map(marker => (
            <MapMarker {...marker} />
          ))}
        </GoogleMapReact>
      </div>
    )
  }
}
